// Agent Signup/Agent Profile Page
export const addFile = (formData) => {
  return fetch("/api/uploads/create", {
    method: "POST",
    body: formData,
  });
};
export const postUpload = (uploadData) => {
  return fetch("/api/uploads", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(uploadData),
  });
};
// Agent Signup
export const addAgent = (agentData) => {
  return fetch("/api/agents", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(agentData),
  });
};
export const createUser = (userData) => {
  return fetch("api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const postNotice = (noticeData) => {
  return fetch("/api/notices", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(noticeData),
  });
};
// Agent Profile
export const getAgentProfile = (agent_id) => {
  return fetch(`/api/agents/${agent_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateUser = (userData, user_id) => {
  return fetch(`api/users/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const getUserProfile = (user_id) => {
  return fetch(`api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Edit Agent Profile
export const updateAgent = (agentData, agent_id) => {
  return fetch(`/api/agents/${agent_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(agentData),
  });
};
export const deleteAgent = (agent_id) => {
  return fetch(`/api/agents/${agent_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Login Page
export const loginUser = (userData) => {
  return fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
// Agent Search Page
export const getPremiumAgents = () => {
  return fetch(`/api/agents/premium`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByCity = (city) => {
  return fetch(`/api/agents/city/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByName = (name) => {
  return fetch(`/api/agents/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByEmail = (email) => {
  return fetch(`/api/agents/email/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getCityNames = (city) => {
  return fetch(`/api/agents/city-searchbar/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getRecentAgents = () => {
  return fetch(`/api/agents/recently-added/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentNames = (name) => {
  return fetch(`/api/agents/name-searchbar/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentFullNames = (name) => {
  return fetch(`/api/agents/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByCityAndName = (city, name) => {
  return fetch(`/api/agents/city/${city}/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAgentByCityAndFullName = (city, name) => {
  return fetch(`/api/agents/city/${city}/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Busca Agent Search Page
export const getBuscaPremiumAgents = () => {
  return fetch(`/api/agents/busca/premium`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByCity = (city) => {
  return fetch(`/api/agents/busca/city/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByName = (name) => {
  return fetch(`/api/agents/busca/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByEmail = (email) => {
  return fetch(`/api/agents/busca/email/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaRecentAgents = () => {
  return fetch(`/api/agents/busca/recently-added/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaCityNames = (city) => {
  return fetch(`/api/agents/busca/city-searchbar/${city}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentNames = (name) => {
  return fetch(`/api/agents/busca/name-searchbar/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentFullNames = (name) => {
  return fetch(`/api/agents/busca/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByCityAndName = (city, name) => {
  return fetch(`/api/agents/busca/city/${city}/name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaAgentByCityAndFullName = (city, name) => {
  return fetch(`/api/agents/busca/city/${city}/full-name/${name}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// Super User
export const getFindahomeNotices = () => {
  return fetch(`api/notices/findahome`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getBuscaNotices = () => {
  return fetch(`api/notices/busca`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const removeNotice = (notice_id) => {
  return fetch(`/api/notices/${notice_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
