import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./signupSuccess.css";

const SignupSuccess = () => {
  return (
    <>
      <Header />

      <div className="signup-success-page busca">
        <h1>¡Éxito!</h1>

        <p>
          ¡Gracias por registrarte! Actualmente estamos revisando la información
          de su perfil para su aprobación. Para acceder y administrar su cuenta,
          visite la página de{" "}
          <a href="/portal-de-agentes" className="link">
            inicio de sesión del agente.
          </a>{" "}
        </p>

        <Link to={"/"}>
          <button>Volver a Casa</button>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default SignupSuccess;
