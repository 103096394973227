import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Import Forms/Components
import Contact from "./pages/Contact/index";
import HomeLoans from "./pages/HomeLoans";
import AgentPortal from "./pages/AgentPortal";
import Home from "./pages/Home/index";
import Search from "./pages/Search/index";
import AgentSearch from "./pages/AgentSearch";
import AgentProfile from "./pages/AgentProfile";
import AgentSignup from "./pages/AgentSignup";
import SignupSuccess from "./pages/SignupSuccess";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactanos" element={<Contact />} />
        <Route path="/prestamos" element={<HomeLoans />} />
        <Route path="/encuentra-un-agente" element={<AgentSearch />} />
        <Route path="/perfil-agente/:id" element={<AgentProfile />} />
        <Route path="/agente-inscribirse" element={<AgentSignup />} />
        <Route path="/registro-éxito" element={<SignupSuccess />} />
        <Route path="/buscar" element={<Search />} />
        <Route path="/portal-de-agentes" element={<AgentPortal />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
