import React from "react";
import "./contact.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactForm from "../../components/ContactForm";

const Contact = () => {
  return (
    <div>
      <Header />

      <main>
        {/* Page Heading */}
        <div className="contact-form-heading">
          <h2>Contactanos</h2>
          <p>Complete el siguiente formulario y nos comunicaremos mañana.</p>
          <hr className="contact-form-hr" />
        </div>

        {/* The Form */}
        <ContactForm />
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
