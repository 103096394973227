import {
  APILoader,
  PlacePicker,
} from "@googlemaps/extended-component-library/react";
import React from "react";
import "./autocomplete.css";

export default function PlacesAutocomplete({
  formattedAddress,
  setFormattedAddress,
}) {
  const handlePlaceChange = (e) => {
    setFormattedAddress(e.target.value?.formattedAddress ?? "");
  };
  const countries = ["us", "pr"];

  return (
    <div>
      <APILoader
        apiKey="AIzaSyAzV-_9LI0kvhbhpYkx5yK3RM6Sw8y0ftc"
        solutionChannel="GMP_GCC_placepicker_v1"
      />
      <div className="container">
        <PlacePicker
          country={countries}
          placeholder="Enter a place to see its address"
          type="(cities)"
          onPlaceChange={handlePlaceChange}
        />
        {/* <div className="result">{formattedAddress}</div> */}
      </div>
    </div>
  );
}
